<template>
    <BasicCard
        :loading="loading"
        class="background_accent"
    >
        <template v-slot:feature>
            <v-chip class="primary ml-2">DID</v-chip>
            <v-icon right x-large>mdi-phone-incoming</v-icon>
        </template>
        <p class="title text--primary mt-1" v-if="data.number">
            {{ data.number }}
        </p>

        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">DID ID</span>
            </v-col>
            <v-col cols="7">
                {{ data.i_did_number }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Frozen</span>
            </v-col>
            <v-col cols="7">
                {{ data.frozen }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Description/SZU</span>
            </v-col>
            <v-col cols="7">
                {{ data.description }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">State/Area</span>
            </v-col>
            <v-col cols="7">
                {{ data.city_name }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Country</span>
            </v-col>
            <v-col cols="7">
                {{ data.country_name }}
            </v-col>
        </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip
                :href="
                    'https://admin.breezeconnect.com.au:8558/newui/did_inventory/did_number/list;number=' +
                    data.number
                "
                text="PortaOne"
                icon="mdi-open-in-new"
                color="primary"
                tooltip="View DID in PortaOne Admin"
                flat
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
export default {
    name: 'DIDCard',
    components: { ButtonWithTooltip, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
