<template>
    <BasicCard :loading="loading" title="Carrier Detail">
        <template v-slot:feature>
            <v-img
                src="../../../assets/img/admin/logo/netsip.png"
                contain
                :max-width="100"
                v-if="data.i_vendor == 6"
            />
            <v-img
                src="../../../assets/img/admin/logo/vxgroup.jpg"
                contain
                :max-width="100"
                v-if="data.i_vendor == 8"
            />
        </template>
        <p class="title text--primary mt-1">{{ data.vendor_name }}</p>
        <v-row dense v-if="data.carrier_status == 'unknown'">
            <v-col cols="12">
                <span class="text-disabled"
                    >Integrations are not supported for this carrier.</span
                >
            </v-col>
        </v-row>
        <v-row
            dense
            v-if="data.carrier_status && data.carrier_status != 'unknown'"
        >
            <v-col cols="5">
                <span class="font-weight-bold">Carrier Status</span>
            </v-col>
            <v-col cols="7">
                <DIDCarrierStatus :status="data.carrier_status" small />
            </v-col>
        </v-row>
        <v-row dense v-if="data.netsip">
            <v-col cols="5">
                <span class="font-weight-bold">Number</span>
            </v-col>
            <v-col cols="7">
                {{ data.netsip.country_code }}{{ data.netsip.area_code
                }}{{ data.netsip.indial_number }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Account</span>
            </v-col>
            <v-col cols="7">
                {{ data.netsip.netsip_account }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Start Date</span>
            </v-col>
            <v-col cols="7">
                <LocalDateTime :value="data.netsip.start_date" />
            </v-col>
        </v-row>
        <v-row dense v-if="data.netsip && data.netsip.end_date">
            <v-col cols="5">
                <span class="font-weight-bold">End Date</span>
            </v-col>
            <v-col cols="7">
                <LocalDateTime
                    :value="data.netsip.end_date"
                    v-if="data.netsip.end_date"
                />
            </v-col>
        </v-row>
        <v-row dense v-if="data.netsip">
            <v-col cols="12">
                <span class="text--disabled"
                    >Last updated
                    <LocalDateTime :value="data.netsip.last_sync" relative />
                    via NetSIP API.</span
                >
            </v-col>
        </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip
                text="Update"
                icon="mdi-sync"
                color="primary"
                tooltip="Update NetSIP DID Info"
                flat
                disabled
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import LocalDateTime from '../../pieces/LocalDateTime';
import DIDCarrierStatus from '../../pieces/DID/DIDCarrierStatus';
export default {
    name: 'DIDCarrierCard',
    components: {
        DIDCarrierStatus,
        LocalDateTime,
        ButtonWithTooltip,
        BasicCard,
    },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
